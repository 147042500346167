const ADVISE_SOLAR_API = 'https://adzke.pythonanywhere.com/api/create_customer/'

export const postCustomer = async (httpData) => {

    const requestData = {
        email: httpData.email,
        name: httpData.name,
        phone: httpData.phone,
        property_type: httpData.notes[0],
        quarterly_bill: httpData.notes[1],
        roof_type: httpData.notes[2],
        package: httpData.notes[3],
        notes: 'Delivered from Journey',
    }

    let POST_REQUEST_SETTINGS = {
        method: "POST",
        headers: {
            "Content-Type": "application/json",
        },
        body: JSON.stringify(requestData)
    };

    const response = await fetch(ADVISE_SOLAR_API, POST_REQUEST_SETTINGS);
    if (response.status === 201) {
        return {status: response.status, message: "All good baby :D"}
    }
    else {
        const responseData = await response.json()
        return {status: response.status, message: JSON.stringify(responseData)}
    }
}