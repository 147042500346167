import * as React from 'react';
import CssBaseline from '@mui/material/CssBaseline';
import Container from '@mui/material/Container';
import NavBar from './NavBar';
import HomeGrid from './HomeGrid';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import { Home } from './Home';
import { About } from './About';
import Splash from './Splash';
import Banner from './Banner';
import EmailBanner from './EmailBanner';
import Footer from './Footer';

export default function MainContainer() {
  return (
    <Router>
      <CssBaseline />
      <NavBar />
        <Routes>
        <Route path="/home" element={<Home/>} />
        <Route exact path="/" element={<Home/>} />
        </Routes>
        <Container maxWidth='md'>
        <Routes>
        <Route path="/journey" element={<HomeGrid/>} />
        <Route path="/about" element={<About/>} />
        </Routes>
        </Container>
        <Footer/>
        
    </Router>
  );
}