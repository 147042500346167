import React, { useState } from "react"
import Box from '@mui/material/Box';
import TextField from '@mui/material/TextField';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';

//Animcations
import Fade from '@mui/material/Fade';

// Images
import Contact from '../assets/images/contacts.png'
import { postCustomer } from "../HTTP/PostData";
import { isValidEmail } from "../HTTP/HelperFunctions";

export const CustomerDetailspage = ({ nextSection, transition, notes }) => {

    const [name, setName] = useState('')
    const [email, setEmail] = useState('')
    const [phone, setPhone] = useState('')
    const [nameValidation, setNameValidation] = useState(null)
    const [emailValidation, setEmailValidation] = useState(null)

    const checkValidation = () => {
        let valid = true;
        if (!isValidEmail(email)) {
            setEmailValidation(false);
            valid = false;
        }
        else {
            setEmailValidation(true)
        }

        if (!name) {
            setNameValidation(false);
            valid = false;
        }
        else {
            setNameValidation(true);
        }
        if (valid) {
            setEmailValidation(true)
            setNameValidation(true);
            return true;
        }
        return false

    }

    const finish = async () => {
        if (!checkValidation()) {
            return;
        }
        await postCustomer({ email, name, phone, notes })
        nextSection()
    }

    return (
        <Box sx={{ width: '100%', padding: 2, }} >
            <Fade in={transition} timeout={700}>
                <Typography variant="h4" component="h4" sx={{ padding: 1, textAlign: 'center' }}>
                    Contact information.
                </Typography>
            </Fade>
            <Fade in={transition} timeout={700}>
                <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center', padding: 4 }}>
                    <img src={Contact} style={{ width: 100, height: 100 }} />
                </Box>
            </Fade>
            <Box
                component="form"
                sx={{
                    '& .MuiTextField-root': { m: 2 },

                }}
                noValidate
                autoComplete="off"
            >
                <Fade in={transition} timeout={700}>
                    <div style={{ display: 'flex', flexDirection: 'column' }}>
                        <TextField
                            required
                            id="outlined-required"
                            label="Name"
                            type="text"
                            value={name}
                            onChange={(event) => {
                                setName(event.target.value);
                            }}
                            error={nameValidation == false}
                            helperText={nameValidation == false ? "Please add a name." : ''}
                        />
                        <TextField
                            required
                            id="outlined-required"
                            label="Email"
                            type='email'
                            error={emailValidation == false}
                            helperText={emailValidation == false ? "Please enter a valid email address." : ''}
                            value={email}
                            onChange={(event) => {
                                setEmail(event.target.value);
                            }}
                        />
                        <TextField
                            id="outlined-required"
                            label="Phone number"
                            type='number'
                            value={phone}
                            onChange={(event) => {
                                setPhone(event.target.value);
                            }}
                        />
                        <Button onClick={() => finish()} variant="contained" size="small"> <Typography variant='body1' sx={{ padding: 1 }}>
                            Submit
                        </Typography></Button>
                    </div>
                </Fade>
            </Box>
        </Box>
    )
}