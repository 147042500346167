import React from 'react'
import { useTheme } from '@mui/material/styles'; // Import the useTheme hook
import { Box, Button } from "@mui/material";
import Typography from '@mui/material/Typography';
import bannerImage from '../assets/images/banner.png';
import { EmailCatcher } from './EmailCatcher';
import BasicModal from './Modal';

export default function Approach() {
    const theme = useTheme();
    const [open, setOpen] = React.useState(false);


    return (
        <Box sx={{
            backgroundColor: theme.palette.primary.secondary,
            position: 'relative',
            height: 'auto',
            padding: '2em'
        }}>  
        <Box sx={{width: '100%', height: '100%', display: 'flex', justifyContent: 'center', alignItems: 'center', flexDirection: 'column'}}>
        <BasicModal open={open} setOpen={setOpen}/>
        <Typography variant="h4" component="h5" sx={{
                zIndex: 1,
                position: 'relative'
            }} color={theme.palette.secondary.secondary} >
                Our approach
            </Typography>

            <br></br>

            <Typography variant="body" component="body" sx={{
                zIndex: 1,
                position: 'relative',
                textAlign: 'center'
            }} color={theme.palette.secondary.warning} >
                When it comes to your solar journey, we take a holistic approach that goes beyond just selling solar panels. Our seasoned team of experts is dedicated to making your transition to solar energy seamless and rewarding. We start by evaluating your energy consumption patterns and financial objectives, ensuring that the solar system we design is perfectly aligned with your needs. We handle all aspects, from permitting and installation to monitoring and maintenance. With us, you're not just a customer; you're a partner in creating a sustainable, cost-effective energy future.
            </Typography>
            <br></br>
            <Button variant="contained" size="small" color='success' sx={{width: '20em', textAlign: 'center'}} onClick={() => setOpen(true)} > <Typography variant='body1' sx={{ padding: 1, }}  >
                       Learn more
                   </Typography></Button>
            <br></br>
        </Box>
        </Box>
    )
}
