import React from 'react'
import { useTheme } from '@mui/material/styles'; // Import the useTheme hook
import { Box, Button } from "@mui/material";
import Typography from '@mui/material/Typography';
import bannerImage from '../assets/images/banner.png';
import { EmailCatcher } from './EmailCatcher';

export default function Testimonals() {
    const theme = useTheme();


    return (
        <Box sx={{
            backgroundColor: '#D4E3E0',
            position: 'relative',
            height: 'auto',
            padding: '2em'
        }}>  
        <Box sx={{width: '100%', height: '100%', display: 'flex', justifyContent: 'center', alignItems: 'center', flexDirection: 'column'}}>
            <br></br>
            <Typography variant="h4" component="h1" sx={{
                zIndex: 1,
                position: 'relative',
                textAlign: 'center',
                fontStyle: 'italic'
            }} color={theme.palette.secondary.warning} >
                “I couldn't be happier with the recent solar installation performed by this company. From the initial consultation to the final switch-flip, the entire process was smooth and professional. The team took the time to explain every step, and their expertise was evident throughout. They exceeded my expectations in every way, and I'm grateful for their outstanding service.”
            </Typography>
            <br></br>
            <br></br>   
            <Typography variant="body" component="subtitle" sx={{
                zIndex: 1,
                position: 'relative',
                textAlign: 'center',
                fontStyle: 'italic'
            }} color={theme.palette.secondary.warning} >
                — Sarah, Ashmore
            </Typography>
            <br></br>
        </Box>
        </Box>
    )
}
