import React from 'react'
import { useTheme } from '@mui/material/styles'; // Import the useTheme hook
import { Box, Button, Container } from "@mui/material";
import Typography from '@mui/material/Typography';
import bannerImage from '../assets/images/banner.png';
import { EmailCatcher } from './EmailCatcher';
import package1 from '../assets/images/4.svg'
import package2 from '../assets/images/3.svg'
import package3 from '../assets/images/2.svg'
import package4 from '../assets/images/1.svg'


const packages = [
    {id: 0, title: 'Value', img: package1},
    {id: 0, title: 'Standard', img: package2},
    {id: 0, title: 'Premium', img: package3},
    {id: 0, title: 'Premium+', img: package4},
];

export default function Packages() {
    const theme = useTheme();
    const IMAGE_SIZE_WIDTH = '20em'
    const IMAGE_SIZE_HEIGHT = '30em'

    return (
        <Box sx={{
            backgroundColor: theme.palette.primary.secondary,
            position: 'relative',
            height: 'auto',
            padding: '2em'
        }}>
            <Box sx={{ width: '100%', height: '100%', display: 'flex', justifyContent: 'center', alignItems: 'center', flexDirection: 'column' }}>
                <Typography variant="h4" component="h5" sx={{
                    zIndex: 1,
                    position: 'relative'
                }} color={theme.palette.secondary.secondary} >
                    Packages
                </Typography>

                <br></br>
                <div style={{ display: 'flex', flexWrap: 'wrap', justifyContent: 'center', alignItems: 'center', gap: '3em', }} >
                    {packages.map(i => 
                           <Box key={i}>
                           <img style={{ width: IMAGE_SIZE_WIDTH, height: IMAGE_SIZE_HEIGHT }} src={i.img} alt="Service 1" />
                           <Typography variant="h4" component="h5" sx={{
                               zIndex: 1,
                               position: 'relative',
                               textAlign: 'center'
                           }} color={theme.palette.secondary.secondary} >
                               {i.title}
                           </Typography>
                       </Box>
                        )};
                </div>
            </Box>
        </Box>
    )
}
