import React from "react"
import Typography from '@mui/material/Typography';
import { Box, Button, TextField } from "@mui/material";
import OutlinedCard from "./Card";
import { EmailCatcher } from "./EmailCatcher";


export const About = () => {
    return (
        <Box sx={{ width: '100%', display: 'flex', justifyContent: 'center', alignItems: 'center', flexDirection: 'column' }}>
            <br></br>
            <br></br>
            <br></br>
            <br></br>
            <Typography variant="body1">
                Advise Solar is a solar company based in South East Queensland, Australia. We are passionate about providing clean, affordable, and reliable energy solutions for our customers.
            </Typography>
            <br></br>
            {/* <Typography variant="body2">
                • Solar panel installation and maintenance.
            </Typography>
            <Typography variant="body2">
                • Electric vehicle (EV) charging stations and accessories.
            </Typography>
            <Typography variant="body2">
                • Smart home systems and devices.
            </Typography>
            <br></br>
            <Typography variant="body2">
                Our mission is to help you reduce your carbon footprint, save money on your electricity bills, and enjoy the benefits of a smart and sustainable lifestyle. Whether you need a solar system for your home or business, an EV charger for your car, or a smart home device for your convenience, we have the right solution for you.
            </Typography>
            <br></br>
            <Typography variant="body2">
                We are more than just a solar company. We are your trusted advisors on all things solar, EV, and smart home. We will guide you through every step of the process, from consultation to installation to after-sales service. We will also help you access any government incentives or rebates that you may be eligible for.
            </Typography>
            <br></br>
            <Typography variant="body2">
                If you are interested in our services or have any questions, please contact us today. We would love to hear from you and provide you with a free quote.
            </Typography>
            <br></br>
            <Typography variant="h4" component="h4" sx={{ textAlign: 'center' }}>
                Find out more!
            </Typography> */}
            <br></br>
            <div style={{width: '20em'}}>
                <EmailCatcher />
            </div>
            <br></br>
        </Box>
    )
}