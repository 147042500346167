import React, { useState } from "react";

import Button from '@mui/material/Button';
import Fade from '@mui/material/Fade';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
// Icons
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';

export const AdditionalInfo = ({ d, transition, nextSection, }) => {
    const [showInfo, setShowInfo] = useState(false)
    return (
        <React.Fragment>
            <Fade in={transition} timeout={700}>
                <Button key={d.id} onClick={() => nextSection()} variant="contained" size="large"> <Typography fontWeight='600' variant='subtitle2' sx={{ padding: 2 }}>
                    {d.buttonTitle}
                </Typography></Button>
            </Fade>
            {
                d.inverterImg ?
                    <Button key={d.id} onClick={() => setShowInfo(!showInfo)} variant="outlined" size="small">
                        <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }} >
                            <Typography fontWeight='600' variant='body'>
                                Information
                            </Typography>
                            <KeyboardArrowDownIcon sx={{ marginleft: 1 }} />
                        </Box>
                    </Button>
                    : null
            }
            {
                d.inverterImg && showInfo ?
                    <Fade in={showInfo} timeout={700}>
                        <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', textAlign: 'center', flexDirection: 'column' }}>

                            <br></br>
                            <Typography>
                                {d.inverterReview}
                            </Typography>
                            <br></br>
                            {/* <Typography>
                                {d.informationArray.map(i => {
                                    return (
                                        <>
                                            <br></br>
                                            <Typography>
                                                - {i}
                                            </Typography>
                                        </>
                                    )
                                })}
                            </Typography> */}
                        </div>
                    </Fade>
                    : null}
        </React.Fragment>
    )
}
