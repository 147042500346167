
// Images
import Thankyou from '../assets/images/thanks.gif'

import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Fade from '@mui/material/Fade';

export const ThankYou = ({transition}) => {
    return (
        <Box sx={{ width: '100%', padding: 2, }} >
            <Fade in={transition} timeout={700}>
                <Typography variant="h4" component="h4" sx={{ padding: 1, textAlign: 'center' }}>
                    Thank you!
                </Typography>
            </Fade>

            <Fade in={transition} timeout={700}>
                <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center', padding: 4 }}>
                    <img src={Thankyou} style={{ width: 150, height: 150 }} />
                </Box>
            </Fade>
            <Fade in={transition} timeout={700}>
                <Typography variant="h5" component="h5" sx={{ padding: 1, textAlign: 'center' }}>
                    Check your emails and kick off your solar journey!
                </Typography>
            </Fade>
        </Box>
    )
}