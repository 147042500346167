import React from "react"
import Typography from '@mui/material/Typography';
import { Box, Container } from "@mui/material";
import OutlinedCard from "./Card";
import { EmailCatcher } from "./EmailCatcher";
import { useTheme } from '@mui/material/styles'; // Import the useTheme hook
import Banner from "./Banner";
import EmailBanner from "./EmailBanner";
import Approach from "./Approach";
import Services from "./Services";
import Packages from "./Packages";
import Testimonals from "./Testimonals";
import Consultation from "./Consultation";

export const Home = () => {

    return (
        <Box sx={{ width: '100%' }}>
            <Banner />
            <EmailBanner />
            <Container maxWidth='md'>
            <Approach />
            </Container>
            <Services/>
            <Packages/>
            <Testimonals/>
            <Consultation/> 
        </Box>
    )
}