import React from 'react'
import { useTheme } from '@mui/material/styles'; // Import the useTheme hook
import { Box, Button } from "@mui/material";
import Typography from '@mui/material/Typography';
import charger from '../assets/images/charger.png';
import battery from '../assets/images/battery-charge.png';
import dashboard from '../assets/images/dashboard.png';
import solarPanel from '../assets/images/solar-panel.png';
import BasicModal from './Modal';



export default function Consultation() {
    const theme = useTheme();
    const IMAGE_SIZE = '8em'
    const [open, setOpen] = React.useState(false);

    return (
        <Box sx={{
            backgroundColor: theme.palette.primary.main,
            position: 'relative',
            height: '50vh',
            width: 'auto',
            padding: '2em'
        }}>
            <Box sx={{ width: '100%', height: '100%', display: 'flex', justifyContent: 'center', alignItems: 'center', flexDirection: 'column' }}>
                <BasicModal open={open} setOpen={setOpen}/>
                <Typography variant="h4" component="h5" sx={{
                    zIndex: 1,
                    position: 'relative',
                    textAlign: 'center'
                }} color={theme.palette.secondary.main}  >
                    One click away, to Brighter living
                </Typography>

                <br></br>
                <br></br>
                <br></br>
                <br></br>
                <br></br>

                <Button variant="contained" size="small" color='warning' sx={{ width: '25em', textAlign: 'center', padding: '1em' }} onClick={() => setOpen(true)} > <Typography variant='body1' sx={{ padding: 1, }}  >
                    Book a consultation
                </Typography></Button>
            </Box>
        </Box>
    )
}
