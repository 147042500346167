import React, { useState, useEffect } from 'react';
import Box from '@mui/material/Box';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';

// Components
import { CustomerDetailspage } from './CustomerDetailPage'



// Data
import { data } from '../data/buttonData'

//Animcations
import Fade from '@mui/material/Fade';

import { ThankYou } from './ThankYou';
import { Page } from './Page';
import { AdditionalInfo } from './AdditionalInfo';

export default function HomeGrid() {

    const [stepCount, setStepCount] = useState(0)
    const [transition, setTransition] = useState(false)
    const [notes, setNotes] = useState([])

    useEffect(() => {
        setTransition(true)
    }, [stepCount]);

    const nextSection = (title) => {
        setStepCount(stepCount + 1)
        if (title){
            const noteArray = [...notes];
            noteArray.push(title)
            setNotes(noteArray)
        }
    }

    const previousSection = () => {
        if (stepCount > 0) {
            const noteArray = [...notes];
            noteArray.pop()
            setNotes(noteArray)
            setStepCount(stepCount - 1)
        }
    }

    const SolarInfo = ({ data }) => {
        return (
            <Box>
                <Fade in={transition} timeout={700}>
                    <Typography variant="h4" component="h4" sx={{ padding: 1, textAlign: 'center' }}>
                        {data.title}
                    </Typography>
                </Fade>
                <Fade in={transition} timeout={700}>
                    <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center', padding: 4 }}>
                        <img src={data.img} style={{ width: 100, height: 100 }} />
                    </Box></Fade>
                <Stack spacing={3} >
                    {data.buttonData.map(d => {
                        return (
                            <AdditionalInfo d={d} key={d.id} transition={transition} nextSection={() => nextSection(d.buttonTitle)} />
                        )
                    })}
                </Stack>
            </Box>
        )
    }

    const MainView = () => {
        switch (stepCount) {
            case 0:
            case 1:
            case 2:
            case 3:
                return <SolarInfo data={data[stepCount]} />
            case 4:
                return <CustomerDetailspage transition={transition} nextSection={() => nextSection()} notes={notes} />
            case 5:
                return <ThankYou transition={transition} />
            default:
                return null;
        }
    }

    return (
        <Page data={data} previousSection={previousSection} stepCount={stepCount}  >
            <MainView />
        </Page>
    );
}