// Images
import Bill from '../assets/images/electricity-bill.png'
import SolarPackages from '../assets/images/house.png'
import House from '../assets/images/home.png'
import Roof from '../assets/images/roof.png'
import GroWatt from '../assets/images/growattlogo.png'
import Twpanel from '../assets/images/TWSolar.png'


export const data = [
    {
        id: 1,
        title: 'What is your property type?',
        img: House,
        buttonData: [
            { id: 0, buttonTitle: 'Residential', inverterImg: '', inverterReview: '', panelImg: '', panelReview: '' },
            { id: 1, buttonTitle: 'Commercial', inverterImg: '', inverterReview: '', panelImg: '', panelReview: '' }
        ]
    },
    {
        id: 2,
        title: 'How much is your quarterly electricity bill?',
        img: Bill,
        buttonData: [
            { id: 0, buttonTitle: '$300 - $600', inverterImg: '', inverterReview: '', panelImg: '', panelReview: '' },
            { id: 1, buttonTitle: '$600 - $900', inverterImg: '', inverterReview: '', panelImg: '', panelReview: '' },
            { id: 2, buttonTitle: '$900 - $1200', inverterImg: '', inverterReview: '', panelImg: '', panelReview: '' },
            { id: 3, buttonTitle: '$1200+', inverterImg: '', inverterReview: '', panelImg: '', panelReview: '' }
        ]
    },
    {
        id: 3,
        title: 'What type of roof do you have?',
        img: Roof,
        buttonData: [
            { id: 0, buttonTitle: 'Tin', inverterImg: '', inverterReview: '', panelImg: '', panelReview: '' },
            { id: 1, buttonTitle: 'Tile', inverterImg: '', inverterReview: '', panelImg: '', panelReview: '' },
            { id: 2, buttonTitle: 'Other', inverterImg: '', inverterReview: '', panelImg: '', panelReview: '' },
        ]
    },
    {
        id: 4,
        title: 'Packages',
        img: SolarPackages,
        buttonData: [
            { id: 0, buttonTitle: 'Value', inverterImg: GroWatt, inverterReview: `Are you looking to start your solar journey without breaking the bank? Our Value Package is the perfect choice. With this package, you'll get high-quality solar panels that will significantly reduce your electricity bills and your carbon footprint. It's a budget-friendly option that provides long-term savings, making clean energy accessible to everyone.`, panelImg: Twpanel, panelReview: `` },
            { id: 1, buttonTitle: 'Standard', inverterImg: GroWatt, inverterReview: `Upgrade your energy independence with our Standard Package. This package offers top-notch solar panels combined with advanced monitoring and control systems. You'll not only enjoy substantial savings on your energy bills but also have the flexibility to track and manage your energy production and consumption. Take control of your energy future with the Standard Package.`, panelImg: Twpanel, panelReview: `When it comes to reliability, JA Solar panels shine bright. These panels are built to last, with a robust design that can withstand extreme weather conditions.
            Whether you live in an area with scorching summers or freezing winters, JA Solar panels will continue to operate efficiently. If durability and longevity are your priorities, JA Solar panels are a solid choice.` },
            { id: 2, buttonTitle: 'Premium', inverterImg: GroWatt, inverterReview: `For those who demand the best, our Premium Package is designed for you. This package features the latest solar technology, premium-grade panels, and state-of-the-art energy storage options. You'll experience maximum energy efficiency, even during cloudy days. Plus, you'll be able to store excess energy for use when the sun isn't shining. Elevate your home's energy potential with the Premium Package.`, panelImg: Twpanel, panelReview: `Jinko panels are known for their innovation and commitment to sustainability. They consistently deliver high energy conversion rates, ensuring you get the most out of your solar system. But what sets Jinko apart is their dedication to eco-friendly manufacturing processes. Choosing Jinko panels not only reduces your energy bills but also contributes to a greener future.` },
            { id: 3, buttonTitle: 'Premium +', inverterImg: GroWatt, inverterReview: `If you want the ultimate solar experience, our Premium+ Package is the pinnacle of clean energy solutions. This package includes cutting-edge solar panels, top-tier energy storage, and smart home integration. You'll have the ability to power your entire home with renewable energy, even during grid outages. Say goodbye to power interruptions and hello to complete energy independence with the Premium+ Package.
            `, panelImg: Twpanel, panelReview: `REC Solar panels redefine the standards of solar excellence.
            They consistently deliver impressive energy conversion rates, resulting in substantial savings on your energy bills.
            REC Solar panels are also built to endure various environmental challenges, making them a durable choice for long-term energy production. If you're looking for excellence and performance, REC Solar panels are a top contender.` }
        ]
    },
    {
        id: 5,
        title: 'Packages',
        img: SolarPackages,
        buttonData: [
            'Value', 'Standard', 'Premium', 'Premium +'
        ]
    },
]
