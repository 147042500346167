import './App.css';
import MainContainer from './components/MainContainer';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import '@fontsource/montserrat/600.css';

function App() {

  const outerTheme = createTheme({
    typography:  {
      h5: {
        fontFamily: 'arimo',
      }, 
      h4: {
        fontFamily: 'arimo',
      }, 
      h3: {
        fontFamily: 'arimo',
      },
      subtitle1: {
        fontFamily: 'roboto',
      }
    },
    palette: {
      primary: {
        main: '#03604D'
      },
      secondary: {
        main: '#FFFFFF'
      },
      warning: {
        main: '#f4d916'
      },
      success: {
        main: '#000000'
      }
      
    },
  });

  return (
    <ThemeProvider theme={outerTheme}>
      <MainContainer />
    </ThemeProvider>
  );
}

export default App;
