import * as React from 'react';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Modal from '@mui/material/Modal';
import { CustomerDetailspage } from './CustomerDetailPage';

const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  bgcolor: 'background.paper',
  border: '2px solid #000',
  boxShadow: 24,
  margin: '5% auto',
  p: 4,
  overflow: 'auto',
  msOverflowStyle: 'none',
  scrollbarWidth: 'none', 
};

const contentStyle = {
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  textAlign: 'center',
  flexDirection: 'column',
  overflow: 'auto', 
  maxHeight: '400px',

}



export default function BasicModal({ open, setOpen}) {
  const handleClose = () => setOpen(false);
  const [transition, setTransition] = React.useState(true)

  return (
    <div>
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          <CustomerDetailspage transition={transition} nextSection={handleClose} notes={["This person has requested a consultation from the landing page!"]}/>
        </Box>
      </Modal>
    </div>
  );
}