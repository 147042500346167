import React from 'react'
import { useTheme } from '@mui/material/styles'; // Import the useTheme hook
import { Box, Button } from "@mui/material";
import Typography from '@mui/material/Typography';
import charger from '../assets/images/charger.png';
import battery from '../assets/images/battery-charge.png';
import dashboard from '../assets/images/dashboard.png';
import solarPanel from '../assets/images/solar-panel.png';
import BasicModal from './Modal';



export default function Services() {
    const theme = useTheme();
    const IMAGE_SIZE = '8em'
    const [open, setOpen] = React.useState(false);


    return (
        <Box sx={{
            backgroundColor: theme.palette.primary.main,
            position: 'relative',
            height: 'auto',
            width: 'auto',
            padding: '2em'
        }}>
            <Box sx={{ width: '100%', height: '100%', display: 'flex', justifyContent: 'center', alignItems: 'center', flexDirection: 'column' }}>
            <BasicModal open={open} setOpen={setOpen}/>
                <Typography variant="h4" component="h5" sx={{
                    zIndex: 1,
                    position: 'relative'
                }} color={theme.palette.secondary.main}  >
                    Our Services
                </Typography>

                <br></br>
                <br></br>
                <div style={{ display: 'flex', flexWrap: 'wrap', justifyContent: 'center', alignItems: 'center', gap: '5em',  textAlign: 'center'}} >
                    <div style={{ flexDirection: 'column', justifyContent: 'center', alignItems: 'center' }}>
                        <img style={{ width: IMAGE_SIZE, height: IMAGE_SIZE, filter: 'brightness(0) invert(1) grayscale(100%) brightness(1.5)', }} src={solarPanel} alt="Service 1" />
                        <Typography variant="h4" component="h5" sx={{
                            zIndex: 1,
                            position: 'relative'
                        }} color={theme.palette.secondary.main}  >
                            Solar
                        </Typography>
                    </div>
                    <div style={{ flexDirection: 'column', justifyContent: 'center', alignItems: 'center' }}>
                        <img style={{ width: IMAGE_SIZE, height: IMAGE_SIZE, filter: 'brightness(0) invert(1) grayscale(100%) brightness(1.5)', }} src={battery} alt="Service 1" />
                        <Typography variant="h4" component="h5" sx={{
                            zIndex: 1,
                            position: 'relative'
                        }} color={theme.palette.secondary.main}  >
                           Batteries
                        </Typography>
                    </div>
                    <div style={{ flexDirection: 'column', justifyContent: 'center', alignItems: 'center' }}>
                        <img style={{ width: IMAGE_SIZE, height: IMAGE_SIZE, filter: 'brightness(0) invert(1) grayscale(100%) brightness(1.5)', }} src={dashboard} alt="Service 1" />
                        <Typography variant="h4" component="h5" sx={{
                            zIndex: 1,
                            position: 'relative'
                        }} color={theme.palette.secondary.main}  >
                            Analytics
                        </Typography>
                    </div>
                    <div style={{ flexDirection: 'column', justifyContent: 'center', alignItems: 'center' }}>
                        <img style={{ width: IMAGE_SIZE, height: IMAGE_SIZE, filter: 'brightness(0) invert(1) grayscale(100%) brightness(1.5)', }} src={charger} alt="Service 1" />
                        <Typography variant="h4" component="h5" sx={{
                            zIndex: 1,
                            position: 'relative'
                        }} color={theme.palette.secondary.main}  >
                           EV
                        </Typography>
                    </div>
                </div>
                <br></br>
                <br></br>
                <br></br>

                <Button variant="contained" size="small" color='warning' sx={{ width: '20em', textAlign: 'center' }} onClick={() => setOpen(true)}> <Typography variant='body1' sx={{ padding: 1, }}  >
                    Learn more
                </Typography></Button>
            </Box>
        </Box>
    )
}
