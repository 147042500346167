import React, { useEffect } from 'react';
import Box from '@mui/material/Box';

// Icons
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import LinearWithValueLabel from './Progress';
import IconButton from '@mui/material/IconButton';


export const Page = ({ stepCount, children, previousSection, data, }) => {

    const calculateProgress = () => {
        return (stepCount / data.length) * 100
    }
    useEffect(() => {
        calculateProgress()
    }, [stepCount])

    return (
        <Box sx={{
            width: '100%',
            paddingTop: 3,
        }} >
            {stepCount < 5 ?
                <React.Fragment>
                    <IconButton aria-label="delete" onClick={() => previousSection()} sx={{ marginBottom: 1 }} >
                        <ArrowBackIcon color='primary' fontSize='large' />
                    </IconButton>
                    <LinearWithValueLabel progressData={calculateProgress()} />
                </React.Fragment>
                : <br />}
            <Box sx={{
                display: 'flex',
                flexDirection: 'column',
            }}>
                <Box sx={{ flex: 1 }}>
                    {children}
                </Box>
            </Box>
        </Box>
    )
}