import React from 'react'
import { useTheme } from '@mui/material/styles'; // Import the useTheme hook
import { Box } from "@mui/material";
import Typography from '@mui/material/Typography';
import bannerImage from '../assets/images/banner.png';

export default function Banner() {
    const theme = useTheme();

    const overlayStyle = {
        content: '""',
        position: 'absolute',
        zIndex: 1,
        top: 0,
        left: 0,
        right: 0,
        bottom: 0,
        backgroundColor: 'rgba(0, 0, 0, 0.5)', // Adjust opacity here
    };

    return (
        <Box sx={{
            backgroundColor: 'black',
            position: 'relative',
            backgroundImage: `url(${bannerImage})`,
            height: 'auto',
            backgroundSize: 'cover',
            backgroundRepeat: 'no-repeat',
            backgroundPosition: 'center',
            height: '100vh',
            padding: '2em'
        }}>  <div style={overlayStyle}></div>
        <Box sx={{width: '100%', height: '100%', display: 'flex', justifyContent: 'center', alignItems: 'center', flexDirection: 'column'}}>
        <Typography variant="h3" component="h1" sx={{
                zIndex: 1,
                position: 'relative'
            }} color={theme.palette.secondary.main} >
                Brighter future, Brighter living
            </Typography>

            <br></br>
            <br></br>
            <br></br>

            <Typography variant="h6" component="subtitle" sx={{
                zIndex: 1,
                position: 'relative'
            }} color={theme.palette.secondary.main} >
                Install solar to save money on energy bills while reducing your carbon footprint and contributing to a more sustainable future.
            </Typography>
            {/* <Typography variant="h3" component="h2" sx={{ textAlign: 'center' }} color={theme.palette.secondary.main}>
                SOLAR MADE EASY
            </Typography> */}
            <br></br>
        </Box>
        </Box>
    )
}
