import React from 'react'
import { useTheme } from '@mui/material/styles'; // Import the useTheme hook
import { Box } from "@mui/material";
import Typography from '@mui/material/Typography';
import { EmailCatcher } from './EmailCatcher';

export default function EmailBanner() {
    const theme = useTheme();


    return (
        <Box sx={{
            backgroundColor: theme.palette.primary.main,
            position: 'relative',
            height: 'auto',
            height: '75vh',
            padding: '2em'
        }}>  
        <Box sx={{width: '100%', height: '100%', display: 'flex', justifyContent: 'center', alignItems: 'center', flexDirection: 'column'}}>
        <Typography variant="h4" component="h5" sx={{
                zIndex: 1,
                position: 'relative'
            }} color={theme.palette.secondary.main} >
                Start Your Journey
            </Typography>

            <br></br>

            <Typography variant="body" component="subtitle" sx={{
                zIndex: 1,
                position: 'relative',
                textAlign: 'center'
            }} color={theme.palette.secondary.main} >
                Sign up with your email address to receive access to our exclusive solar savings calculator 
            </Typography>
            {/* <Typography variant="h3" component="h2" sx={{ textAlign: 'center' }} color={theme.palette.secondary.main}>
                SOLAR MADE EASY
            </Typography> */}
            <br></br>
            <Box sx={{width: '20em'}}>
            <EmailCatcher/> 
            </Box>
            <br></br>
            <Typography variant="body2" component="body2" sx={{
                zIndex: 1,
                position: 'relative'
            }} color={theme.palette.secondary.main} >
                We respect your privacy.
            </Typography>
        </Box>
        </Box>
    )
}
