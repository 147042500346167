import * as React from 'react';
import PropTypes from 'prop-types';
import LinearProgress from '@mui/material/LinearProgress';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';


function LinearProgressWithLabel(props) {
  return (
    <Box sx={{ display: 'flex', alignItems: 'center' }}>
      <Box sx={{ width: '100%', mr: 1 }}>
        <LinearProgress variant="determinate" {...props} sx={{height: 8, borderRadius: 1}} />
      </Box>
    </Box>
  );
}

LinearProgressWithLabel.propTypes = {
  /**
   * The value of the progress indicator for the determinate and buffer variants.
   * Value between 0 and 100.
   */
  value: PropTypes.number.isRequired,
};

export default function LinearWithValueLabel({progressData}) {
  const [progress, setProgress] = React.useState(progressData);

  React.useEffect(() => {
    setProgress(progressData)
  }, [progressData])

  return (
    <Box sx={{ width: '100%' }}>
      <LinearProgressWithLabel value={progress}  />
    </Box>
  );
}