import React, { useState } from "react"
import Typography from '@mui/material/Typography';
import { Box, Button, TextField } from "@mui/material";
import { isValidEmail } from "../HTTP/HelperFunctions";
import { postCustomer } from "../HTTP/PostData";

export const EmailCatcher = () => {
    const [email, setEmail] = useState('')
    const name = 'About page'
    const phone = ''
    const notes = ['This was sent from the email catcher on landing page / about page']
    const [emailValidation, setEmailValidation] = useState(null)
    const [sent, setSent] = useState(false)


    const data = {
        email, name, phone, notes
    }

    const checkValidation = () => {
        let valid = true;
        if (!isValidEmail(email)) {
            setEmailValidation(false);
            valid = false;
        }
        else {
            setEmailValidation(true)
        }
        if (valid) {
            setEmailValidation(true)
            return true;
        }
        return false

    }

    const finish = async () => {
        if (!checkValidation()) {
            return;
        }
        await postCustomer(data)
        setSent(true)
    }

    return (
        <div style={{ display: 'flex', flexDirection: 'column', pading: 2, maxWidth: '20em', justifyContent: 'space-around' }}>
            {!sent ?
                < React.Fragment >
                   <TextField
                       required
                       id="outlined-required"
                       label="Email"
                       type='email'
                       variant='filled'
                       error={emailValidation == false}
                       helperText={emailValidation == false ? "Please enter a valid email address." : ''}
                       value={email}
                       onChange={(event) => {
                           setEmail(event.target.value);
                       }}
                       sx={{
                        width: "100%",
                        "& .MuiInputBase-input": {
                          color: "black", // Change text color here
                        },
                        "& .MuiFilledInput-root": {
                          backgroundColor: "white", // Change background color here
                        },
                      }}
                       color={'primary'}
                   />
                   <br></br>
                    <Box sx={{display: 'flex', justifyContent: 'center', alignItems: 'center'}}>
                    <Button variant="contained" size="small" color='warning' sx={{width: '10em', textAlign: 'center'}} > <Typography variant='body1' sx={{ padding: 1, }} onClick={() => finish()} >
                       Submit
                   </Typography></Button>
                    </Box>
                   </React.Fragment>
           : <Typography variant="h5" component="h5" sx={{ textAlign: 'center' }}>
          Thank you for your interest!
       </Typography> }

        </div >
    )
}